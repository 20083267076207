<template>
  <v-container class="fill-height" fluid data-cy="forgot-password-page">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <ForgotPassword
          v-if="authMethod == 'internal'"
          :organisationId="queryOrganisationId"
        />
        <ForgotPasswordCas
          v-if="authMethod == 'CAS3'"
          :organisationId="queryOrganisationId"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ForgotPassword from "@/modules/auth/components/ForgotPassword.vue";
import ForgotPasswordCas from "@/modules/auth/components/ForgotPasswordCas.vue";
import ForcedOrganisationIdPages from "@/modules/auth/mixins/forcedOrganisationIdPages.mixin.js";

export default {
  name: "ForgotPasswordPage",
  mixins: [ForcedOrganisationIdPages],
  components: {
    ForgotPassword,
    ForgotPasswordCas,
  },
  computed: {
    authMethod() {
      return this.$store.getters["config/getAuthMethod"];
    },
  },
};
</script>
